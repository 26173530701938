import { useAbsenceModeStore } from '@/modules/profile-page/stores/AbsenceModeStore';
import { AbsenceMode } from 'shared/types/AbsenceMode';

export const initAbsenceMode = (absenceMode: AbsenceMode) => {
  const absenceModeStore = useAbsenceModeStore();

  absenceModeStore.$patch((state) => {
    state.isActive = absenceMode.isActive;
    state.absenceText = absenceMode.text;
  });
};
