<script setup lang="ts">
import { computed } from 'vue';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import ProfessionContent from '@/shared/components/ProfessionContent.vue';
import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';

const { admissionDate } = useSettingsStore();
const { profile } = useLawyerStore();
const { profileProductId } = useProfileProductId();
const { getTranslationByProductId } = TranslationHelper();
const profileProductIdText = computed(() =>
  getTranslationByProductId('profileProductIdText', profileProductId, { profession: profile.profession })
);
</script>

<template>
  <ProfessionContent
    :dataTestId="'profile-page-verified-badge'"
    :positioningClass="'relative !-left-36 md:!-left-16 2xl:!-top-16'"
    :profession="profile.profession"
    :isVerified="admissionDate"
    :profileProductIdText="profileProductIdText" />
</template>
