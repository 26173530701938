<script setup lang="ts">
import { toRefs, provide, onMounted } from 'vue';
import { UserResponse } from '@shared/types/User';
import { initData } from '@/modules/profile-page/inits';
import ProfilePage from '@/views/ProfilePage.vue';
import { IAppConfig } from 'shared/types/AppData';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { Statistic } from '@/shared/service/StatisticService';

const props = defineProps<{
  data: UserResponse;
  appConfig: IAppConfig;
  appName: string;
}>();

const { data, appConfig, appName } = toRefs(props);

initData(data.value, appConfig.value);

const statistic = new Statistic(data.value, appName.value);
provide(statisticKey, statistic);

onMounted(() => {
  statistic.sendOnPageLoad();
});
</script>

<template>
  <ProfilePage />
</template>

<style lang="scss">
@import '@anwalt.de/tailwind-design-system/src/index.css';
@import '@anwalt.de/tailwind-design-system/dist/style.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';
</style>
