<script setup lang="ts">
import { useArticleStore } from '@/modules/profile-page/stores/ArticleStore';
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import { useI18n } from 'vue-i18n';

const { articleSummary } = useArticleStore();
const { t } = useI18n();
</script>
<template>
  <SectionHeadline :headline="t('articlesHeading')" :counter="articleSummary.totalCount" />
</template>
