<script setup lang="ts">
import { useUserStore } from '../../stores/UserStore';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMessageQuote } from '@fortawesome/pro-solid-svg-icons';
import { storeToRefs } from 'pinia';

const { motto } = storeToRefs(useUserStore());
</script>

<template>
  <blockquote v-if="motto.length">
    <div class="flex gap-4 anw-text-p1 text-neutral-700 mb-5">
      <div>
        <FontAwesomeIcon :icon="faMessageQuote" aria-hidden="true" fixed-width />
      </div>
      <div class="flex-1" v-html="motto"></div>
    </div>
  </blockquote>
</template>
