<script setup lang="ts">
import { ref, computed, type Ref, inject } from 'vue';
import { AnwButton } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { CONTACTS } from '@/modules/profile-page/constants/ContactType';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faPhonePlus, faMobile, faPrint } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { PhoneNumberHelper } from '@/modules/profile-page/helpers/PhoneNumberHelper';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { storeToRefs } from 'pinia';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { statisticKey } from '@/shared/constants/ProviderKeys';

const { t } = useI18n();

const props = defineProps<{ dataContactKey: string; dataContactValue: string; dataContactId: number }>();

const { format } = PhoneNumberHelper();

const fullNumber: Ref<{ [key: string]: any }> = computed(
  () => contactDetails.filter((contact) => contact.id === props.dataContactId)[0]
);

const { contactDetails } = useContactDetailsStore();

const showFullNumber: Ref<{ [key: string]: boolean }> = ref({
  [CONTACTS.PHONE_NUMBER]: false,
  [CONTACTS.MOBILE_NUMBER]: false,
  [CONTACTS.EMERGENCY_NUMBER]: false,
  [CONTACTS.WHATSAPP_NUMBER]: false,
  [CONTACTS.FAX_NUMBER]: true,
});

const getIcon = (key: string) => {
  switch (key) {
    case CONTACTS.PHONE_NUMBER:
      return faPhone;
    case CONTACTS.MOBILE_NUMBER:
      return faMobile;
    case CONTACTS.EMERGENCY_NUMBER:
      return faPhonePlus;
    case CONTACTS.WHATSAPP_NUMBER:
      return faWhatsapp;
    case CONTACTS.FAX_NUMBER:
      return faPrint;
    default:
      return faPhone;
  }
};

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLink' + props.dataContactKey),
  [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
});

const { userId } = storeToRefs(useUserStore());
const statistic = inject(statisticKey);

const onClick = () => {
  showFullNumber.value[props.dataContactKey] = true;
  statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_PHONE, entityId: userId.value });
};
</script>

<template>
  <p class="flex gap-3">
    <span class="flex justify-center w-5 shrink-0">
      <FontAwesomeIcon :icon="getIcon(dataContactKey)" class="fa-1x mt-0.5" aria-hidden="true" />
    </span>
    <span v-if="showFullNumber[dataContactKey]">
      {{ format(fullNumber[dataContactKey]) }}
    </span>
    <span v-else>{{ dataContactValue }}</span>
    <AnwButton
      v-bind="dataAttributesGA"
      v-if="!showFullNumber[dataContactKey]"
      :label="t('contactShowInfo')"
      @click="onClick"
      appearance="link"
      class="text-info-500 font-semibold hover:underline focus:no-underline" />
  </p>
</template>
