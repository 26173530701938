import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER, COMPANY } from '@shared/constants/ProfileProductId';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { useCompanyStore } from '@/modules/profile-page/stores/CompanyStore';
import { initLawyerCompanyDetails } from './InitLawyerCompanyDetails';
import { initAbsenceMode } from './InitAbsenceMode';
import Lawyer from '../../../../shared/types/Lawyer';
import Company from '../../../../shared/types/Company';

export const initProfileType = (profileProductId: typeof LAWYER | typeof COMPANY, data: Lawyer | Company) => {
  const profileProduct = useProfileProductId();
  profileProduct.$patch((state) => {
    state.profileProductId = profileProductId;
  });

  if (profileProductId === LAWYER) {
    const lawyerStore = useLawyerStore();
    const lawyerData = data as Lawyer;
    lawyerStore.$patch((state) => {
      state.profile = { ...lawyerData.personal };
      state.userId = lawyerData.userId;
    });
    initLawyerCompanyDetails(lawyerData);
    initAbsenceMode(lawyerData.absenceMode);
  }
  if (profileProductId === COMPANY) {
    const companyStore = useCompanyStore();

    companyStore.$patch((state) => {
      const companyData = data as Company;
      state.name = companyData.name;
      state.companyId = companyData.companyId;
    });
  }
};
