import { useAppStore } from '@/shared/stores/AppStore';
import { IAppConfig } from '../../../shared/types/AppData';

export const initApp = (appConfig: IAppConfig) => {
  const appStore = useAppStore();
  appStore.$patch((state) => {
    state.contactDetailId = appConfig?.contactDetailId;
    state.fallbackCoordinates = appConfig?.geoCoordinates;
    state.showInternalInformation = appConfig?.showInternalInformation;
  });
};
