<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import RatingSummary from '@/shared/components/RatingSummary.vue';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import { RATE_LINK } from '@/modules/profile-page/constants/Links';
import { RATING_LINK } from '@shared/constants/Links';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useMediaQuery } from '@vueuse/core';

const { t } = useI18n();
const { rating } = useRatingStore();
const { profile } = useLawyerStore();
const { profileProductId } = useProfileProductId();
const { uri, displayedName } = useUserStore();
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();

const { dataAttributesGA, updateAttributeGA } = useGoogleAnalytics();

const setRateButtonTitle = computed(() => {
  return (
    getTranslationByProductId('rateButtonTitle', profileProductId, { n: getGenderValueForTranslation(profile.sex) }) +
    ' ' +
    displayedName
  );
});

const isLargeScreen = useMediaQuery('(min-width: 1366px)');

onMounted(() => {
  updateAttributeGA({
    [GA_ATRIBUTES.ACTION]: isLargeScreen.value
      ? t('eventActionClickGiveRatingButton')
      : t('eventActionClickGiveRatingMobile'),
  });
});
</script>

<template>
  <section class="anw-profile-card" v-if="rating.isRateable" :id="NAVBAR_ITEMS.REVIEW">
    <div>
      <SectionHeadline :headline="t('reviewsHeading')" />
      <RatingSummary :rating="rating" class="pt-4" />
      <a
        v-bind="dataAttributesGA"
        class="anw-btn anw-btn-secondary !text-white hover:!no-underline w-full mt-6"
        :title="setRateButtonTitle"
        :href="uri + RATE_LINK">
        {{
          getTranslationByProductId('rateButton', profileProductId, { n: getGenderValueForTranslation(profile.sex) })
        }}
      </a>
      <div v-if="rating.ratingCount" class="grid place-content-center mt-6">
        <a
          class="font-semibold text-info-500"
          :href="uri + RATING_LINK"
          :title="t('showAllRatingsTitle', { name: displayedName })"
          v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickRatingReadAll') }"
          >{{ t('showAllRatings', { count: rating.ratingCount }) }}</a
        >
      </div>
    </div>
  </section>
</template>
