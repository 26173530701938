<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../stores/UserStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '@shared/constants/ProfileProductId';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleInfo, faArrowUpRight } from '@fortawesome/pro-light-svg-icons';

const { t } = useI18n();

const { userId } = useUserStore();
const { profileProductId } = useProfileProductId();

const profileProduct = profileProductId === LAWYER ? 'users' : 'companies';
const internalUrlHost = import.meta.env.VITE_INTERNAL_URL;
const internalUrl = `${internalUrlHost}/${profileProduct}/${userId}`;
</script>
<template>
  <div class="hidden xl:block">
    <div class="grid gap-2 px-6 py-3 bg-info-50 border border-info-200 rounded-md">
      <div class="flex items-center gap-2 text-sm">
        <FontAwesomeIcon :icon="faCircleInfo" class="text-[14px]" aria-hidden="true" />
        {{ t('sidebarInternalHeading') }}
      </div>
      <div class="flex">
        <a :href="internalUrl" target="_blank" class="flex items-center gap-2 text-sm font-bold">
          {{ t('sidebarInternalUserView')
          }}<FontAwesomeIcon :icon="faArrowUpRight" class="text-[12px]" aria-hidden="true" />
        </a>
      </div>
    </div>
  </div>
</template>
