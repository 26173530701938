import { useSocialMediaStore } from '@/modules/profile-page/stores/SocialMediaStore';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { ContactDetailType } from '@shared/types/ContactDetail';
import { SocialMediaProfile } from 'shared/types/SocialMedia';

export const initSocialMedias = (socialMedias: SocialMediaProfile) => {
  const socialMediaStore = useSocialMediaStore();
  const { contactDetails } = useContactDetailsStore();

  socialMediaStore.$patch((state) => {
    state.socialMedias = {
      website: contactDetails.find((c) => c.type === ContactDetailType.BUSINESS)?.website,
      ...socialMedias,
    };
  });
};
