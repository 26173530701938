<script setup lang="ts">
import { computed, inject } from 'vue';
import { AnwLinkSocialMedia } from '@anwalt.de/tailwind-design-system';
import { useSocialMediaStore } from '@/modules/profile-page/stores/SocialMediaStore';
import { SocialMediaKey } from '../../../../../shared/types/SocialMedia';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { useUserStore } from '../../stores/UserStore';
import { storeToRefs } from 'pinia';
const { socialMedias } = useSocialMediaStore();

const availableSocialMedia = computed(() => {
  if (socialMedias) {
    return Object.fromEntries(
      Object.entries(socialMedias).filter(([k, v]) => k != '__typename' && v != null && v != undefined)
    ) as {
      [key in SocialMediaKey]: string;
    };
  }
  return {};
});

const { t } = useI18n();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickSocialMedia'),
});
const { userId } = storeToRefs(useUserStore());
const statistic = inject(statisticKey);
</script>

<template>
  <ul class="flex flex-wrap gap-3" v-if="socialMedias">
    <li v-for="(url, key, index) in availableSocialMedia" :key="index">
      <AnwLinkSocialMedia
        :href="url"
        :type="key"
        v-bind="dataAttributesGA"
        rel="noopener nofollow"
        @click="
          statistic?.sendCounter({
            counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_SOCIAL_MEDIA,
            entityId: userId,
          })
        " />
    </li>
  </ul>
</template>
