<script setup lang="ts">
import { inject } from 'vue';
import { AnwButton } from '@anwalt.de/tailwind-design-system';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useI18n } from 'vue-i18n';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { IMPRINT_RELATIVE_LINK } from '@/modules/profile-page/constants/Links';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { storeToRefs } from 'pinia';

const { displayedName, uri, imprint, userId } = storeToRefs(useUserStore());

const { t } = useI18n();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkImpressum'),
});

const statistic = inject(statisticKey);

const onClick = () => {
  statistic?.sendCounter({
    counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_EXTERNAL_LINK,
    entityId: userId.value,
  });
  window.open(uri.value + IMPRINT_RELATIVE_LINK, '_blank', '"menubar=1,resizable=1,width=600,height=550");');
};
</script>
<template>
  <div v-if="imprint" class="anw-profile-card p-4">
    <AnwButton
      :label="t('companyImprint')"
      :icon="faCircleInfo"
      iconSize="17px"
      appearance="link"
      v-bind="dataAttributesGA"
      @click="onClick"
      :title="t('imprintBtnTitle', { name: displayedName })"
      class="text-neutral-700 font-normal">
    </AnwButton>
  </div>
</template>
