<script setup lang="ts">
import SidebarRating from '@/modules/profile-page/components/Sidebar/SidebarRating.vue';
import SidebarActions from '@/modules/profile-page/components/Sidebar/SidebarActions.vue';
import ProfileInfo from '@/modules/profile-page/components/ProfileInfo.vue';
import SidebarInternal from '@/modules/profile-page/components/Sidebar/SidebarInternal.vue';
import { useAppStore } from '@/shared/stores/AppStore';

const { showInternalInformation } = useAppStore();
</script>

<template>
  <aside>
    <div class="xl:border xl:border-neutral-100 xl:bg-white xl:rounded-xl xl:shadow-lg xl:overflow-hidden">
      <SidebarRating />
      <SidebarActions />
      <ProfileInfo class="border-t border-neutral-200 hidden xl:block" />
    </div>
    <SidebarInternal v-if="showInternalInformation" />
  </aside>
</template>
