import { computed, onMounted, onUnmounted, ref, type Ref } from 'vue';
import { NavbarHelper } from '../helpers/NavbarHelper';
import { ScrollHelper } from '../helpers/ScrollHelper';

export const useNavbar = () => {
  const currentSection = ref<string | null>(null);
  const stickyNav: Ref<HTMLElement | null> = ref(null);
  const stickyNavFloating = ref(false);
  const { createNavbarItems } = NavbarHelper();

  const navClasses = computed(() => (stickyNavFloating.value ? 'z-30' : 'z-20'));
  const availableSections = computed(() => createNavbarItems());

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    const { getBoundingClientRect } = ScrollHelper();
    stickyNavFloating.value = getBoundingClientRect(stickyNav.value as HTMLElement)?.top === 0;
    const barHeight = stickyNav.value?.clientHeight as number;
    const matchingSection = availableSections.value?.find((section) => {
      const rect = getBoundingClientRect(section.id);
      if (!rect) return false;
      return rect.top <= barHeight + window.scrollY && rect.bottom > barHeight;
    });
    currentSection.value = matchingSection ? matchingSection.id : null;
  };

  const scrollToSection = (sectionId: string) =>
    ScrollHelper().scrollToSection(sectionId, stickyNav.value?.clientHeight as number);

  return { availableSections, scrollToSection, currentSection, stickyNav, navClasses };
};
