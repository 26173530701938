<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <div class="bg-neutral-50 w-full h-full border flex items-center justify-center">
    {{ t('noMapAvailable') }}
  </div>
</template>
