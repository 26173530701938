<script setup lang="ts">
import { useProductStore } from '@/modules/profile-page/stores/ProductStore';
import { useI18n } from 'vue-i18n';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { AnwCardWithImage } from '@anwalt.de/tailwind-design-system';
import { useLegalProductsImage } from '@/modules/profile-page/composables/useLegalProductsImage';

const { t } = useI18n();
const { products } = useProductStore();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();
const { getImageUrl } = useLegalProductsImage();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickProducts'),
  [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
});
</script>
<template>
  <ul class="grid [@media(min-width:680px)]:grid-cols-2 gap-4 pt-4">
    <li v-for="(product, index) in products" :key="index">
      <AnwCardWithImage
        class="w-full"
        link-target="_blank"
        :title="product.title"
        :image-uri="getImageUrl(product.title)"
        :image-alt="product.title"
        :uri="product.uri"
        :link-title="product.title"
        :data-attributes="dataAttributesGA">
        <template #content>
          <p class="text-base/[24px] font-semibold text-neutral-700">
            {{ Math.floor(product.priceInEuroCents / 100) }} &euro;
            <span class="font-normal">{{ t('productsVatIncluded') }}</span>
          </p></template
        >
      </AnwCardWithImage>
    </li>
  </ul>
</template>
