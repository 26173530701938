import { Address } from './Address';
import { WorkingTime } from './WorkingTime';

interface ContactDetail {
  id: number;
  type: ContactDetailType;
  address: Address;
  email: string;
  phoneNumber: string;
  mobileNumber: string;
  faxNumber: string;
  whatsappNumber: string;
  emergencyNumber: string;
  website: string;
  uri: string;
  isBarrierFree: boolean;
  coordinates: Coordinates;
  visible: boolean;
  workingTime: WorkingTime;
  seoCityUri: string | null;
  seoCountryUri: string | null;
  companyName: string;
  workingTimeNote: string;
}

interface ModifiedContactDetail extends ContactDetail {
  calculatedDistance: number;
}

enum ContactDetailType {
  BUSINESS = 'Business',
  PRIVATE = 'Private',
  INVOICE = 'Invoice',
  LOCATION = 'Location',
}
enum SEARCH_TYPE_KEY {
  ID = 'id',
  TYPE = 'type',
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

export { type ContactDetail, type ModifiedContactDetail, ContactDetailType, SEARCH_TYPE_KEY, type Coordinates };
