<script setup lang="ts">
import { AnwButton } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { useCookie } from '@/modules/profile-page/composables/useCookie';
const { t } = useI18n();

const emits = defineEmits<{
  (event: 'clickedAcceptCookies', value: boolean): void;
}>();

const acceptCookies = () => {
  emits('clickedAcceptCookies', true);
  useCookie().acceptCookies();
};
</script>
<template>
  <div class="w-full rounded-lg aspect-video bg-neutral-50 flex flex-col items-center justify-center p-3 sm:p-0">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 101 73"
      fill="none"
      class="xs:mb-1 sm:mb-5 w-11 sm:w-28 h-8 sm:h-16 md:h-20">
      <g clip-path="url(#clip0_4814_5692)">
        <path
          d="M98.8882 11.7413C97.729 7.31677 94.303 3.8329 89.9589 2.64839C82.0854 0.5 50.5 0.5 50.5 0.5C50.5 0.5 18.9145 0.5 11.0411 2.64839C6.69699 3.8329 3.27102 7.31677 2.11182 11.7413C0 19.7658 0 36.5 0 36.5C0 36.5 0 53.2342 2.11182 61.2587C3.27102 65.6832 6.69699 69.1671 11.0411 70.3516C18.9203 72.5 50.5 72.5 50.5 72.5C50.5 72.5 82.0854 72.5 89.9589 70.3516C94.303 69.1671 97.729 65.6832 98.8882 61.2587C101 53.24 101 36.5 101 36.5C101 36.5 101 19.7658 98.8882 11.7413Z"
          fill="#FF0000" />
        <path d="M40.1704 51.6955L66.5681 36.5L40.1704 21.3046V51.6955Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_4814_5692">
          <rect width="101" height="72" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
    <div
      class="mt-3 sm:mt-10 md:mt-12 max-w-sm text-center text-sm sm:text-base text-neutral-600"
      v-html="t('video.noCookiesWarning')"></div>
    <AnwButton
      appearance="secondaryOutline"
      :label="t('video.acceptCookies')"
      class="mt-2 sm:mt-5 font-normal text-base md:text-base w-full sm:w-auto"
      @click="acceptCookies()" />
  </div>
</template>
