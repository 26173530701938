import { GalleryImage } from '@shared/types/GalleryImage';
import { useGalleryStore } from '@/modules/profile-page/stores/GalleryStore';

export const initGalleryStore = (gallery: GalleryImage[]) => {
  const galleryStore = useGalleryStore();

  galleryStore.$patch((state) => {
    state.gallery = gallery ? [...gallery] : [];
  });
};
