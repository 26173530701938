<script setup lang="ts">
import { computed } from 'vue';
import { AnwHeading } from '@anwalt.de/tailwind-design-system';
import { AnwLists } from '@anwalt.de/tailwind-design-system';
import { useUserStore } from '../../stores/UserStore';
import { faGlobeStand } from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { internationalLawList } = useUserStore();

const internationalRightList = internationalLawList.map((item) => ({
  content: item.name,
  iso: item.flagCode.toLowerCase(),
}));

const showInternationalRightList = computed(
  () =>
    internationalRightList.length &&
    (internationalRightList.length > 1 || !internationalRightList.find((item) => item.iso === 'de'))
);
</script>

<template>
  <div v-if="showInternationalRightList">
    <AnwHeading :heading="t('internationalRightHeading')" :icon="faGlobeStand" headingClasses="mb-4" />
    <AnwLists :args="internationalRightList" :flags="true" />
  </div>
</template>
