<script setup lang="ts">
import { AnwHeading, AnwCarousel } from '@anwalt.de/tailwind-design-system';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { useI18n } from 'vue-i18n';
import { useCarousel } from '@/modules/profile-page/composables/useCarousel';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';

const { t } = useI18n();
const { carouselImages, carouselBreakpoints } = useCarousel();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickGalleryPhoto'),
});
</script>

<template>
  <section v-if="carouselImages.length">
    <AnwHeading :heading="t('aboutMeImagesHeading')" tag="h2" :icon="faImage" headingClasses="mb-4" />
    <AnwCarousel
      :content="carouselImages"
      :breakpoints="carouselBreakpoints"
      :lightbox="true"
      :data-attributes="dataAttributesGA" />
  </section>
</template>
