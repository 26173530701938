<script setup lang="ts">
import ArticlesHeader from '@/modules/profile-page/components/Articles/ArticlesHeader.vue';
import ArticlesList from '@/modules/profile-page/components/Articles/ArticlesList.vue';
import ArticlesMore from '@/modules/profile-page/components/Articles/ArticlesMore.vue';
import { useArticleStore } from '@/modules/profile-page/stores/ArticleStore';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';

const { articleList } = useArticleStore();
</script>

<template>
  <section class="anw-profile-card" :id="NAVBAR_ITEMS.ARTICLE" v-if="articleList.length">
    <ArticlesHeader />
    <ArticlesList />
    <ArticlesMore />
  </section>
</template>
