<script setup lang="ts">
import { AnwHeading } from '@anwalt.de/tailwind-design-system';
import { AnwLists } from '@anwalt.de/tailwind-design-system';
import { faScreenUsers } from '@fortawesome/pro-light-svg-icons';
import { useAssociationListStore } from '@/modules/profile-page/stores/AssociationsListStore';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const associationListStore = useAssociationListStore();
const { associationList } = storeToRefs(associationListStore);

const valueToSkip = '\r';

const associations = computed(() => {
  return associationList.value
    .filter((item) => item !== valueToSkip)
    .map((item) => ({
      content: item.trim(),
    }));
});
</script>

<template>
  <div v-if="associations.length">
    <AnwHeading :heading="t('membershipsHeading')" :icon="faScreenUsers" headingClasses="mb-4" />
    <AnwLists :args="associations" background="info" />
  </div>
</template>
