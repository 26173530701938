<script setup lang="ts">
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { displayedName } = useUserStore();
</script>
<template>
  <SectionHeadline :headline="t('contactHeading', { name: displayedName })" />
</template>
