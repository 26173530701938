import { WorkingTime } from '@shared/types/WorkingTime';
import { useI18n } from 'vue-i18n';
import { WEEKDAYS } from '@/shared/constants/Weekdays';

export class OpeningHours {
  private openingHours: WorkingTime;
  private openingHoursInformation: { [key: string]: string } = {};
  constructor(openingHours: WorkingTime) {
    this.openingHours = openingHours;
    delete this.openingHours.__typename;
  }

  getOpeningHours() {
    this.mergedDays();
    return this.hasWorkingHours() ? this.openingHoursInformation : null;
  }

  private sameRangeHoursWeekdays(listOfWeekdays: number[]) {
    listOfWeekdays.sort((a, b) => a - b);
    const splittedList = [];
    let chunk = [];
    let previousNumber = -Infinity;

    for (const number of listOfWeekdays) {
      if (number - 1 !== previousNumber && number > 0) {
        if (chunk.length > 0) {
          splittedList.push(chunk);
        }
        chunk = [];
      }
      chunk.push(WEEKDAYS[number]);
      previousNumber = number;
    }
    splittedList.push(chunk);

    return splittedList;
  }

  private defineWeekdayPerHoursRange() {
    const hours: { [key: string]: string[] } = {};

    for (const [weekday, hoursRange] of Object.entries(this.openingHours)) {
      const morningRange = hoursRange[0];
      const eveningRange = hoursRange[1];

      const morningTime = morningRange?.from && morningRange?.to ? `${morningRange.from}–${morningRange.to}` : null;
      const eveningTime = eveningRange?.from && eveningRange?.to ? `${eveningRange.from}–${eveningRange.to}` : null;

      const stringHoursRange =
        morningTime && eveningTime ? `${morningTime}<br>${eveningTime}` : morningTime || eveningTime;

      if (stringHoursRange) {
        hours[stringHoursRange] = hours[stringHoursRange] ? [...hours[stringHoursRange], weekday] : [weekday];
      }
    }
    return hours;
  }

  private getNumberFromDay = (day: string) => WEEKDAYS.indexOf(day);

  private mergedDays() {
    const { t } = useI18n();
    const openingHoursObject: { [key: string]: string } = {};
    const weekdayPerHoursRange = this.defineWeekdayPerHoursRange();

    for (const key in weekdayPerHoursRange) {
      const arrayNumber = weekdayPerHoursRange[key].map(this.getNumberFromDay);
      const sameRangeHoursWeekdays = this.sameRangeHoursWeekdays(arrayNumber);

      sameRangeHoursWeekdays.forEach((element) => {
        const value = element.length >= 2 ? `${t(element[0])}–${t(element[element.length - 1])}` : t(element[0]);
        openingHoursObject[key] = (openingHoursObject[key] || '') + value + ', ';
      });

      openingHoursObject[key] = openingHoursObject[key].slice(0, -2);
    }

    this.openingHoursInformation = { ...openingHoursObject };
  }

  private hasWorkingHours() {
    return Object.values(this.openingHoursInformation).length > 0;
  }
}
