<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { AnwAccordion } from '@anwalt.de/tailwind-design-system';
import { useFaq } from '@/modules/profile-page/composables/useFaq';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import { ScrollHelper } from '../../helpers/ScrollHelper';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { DIRECT_MESSAGE } from '@/modules/profile-page/constants/Links';

const { uri } = useUserStore();

const { t } = useI18n();
const { accordionTogglerHelper, faqs } = useFaq();
const { scrollToSection } = ScrollHelper();
</script>

<template>
  <ul class="flex flex-col">
    <li v-for="faq in faqs" :key="faq.id" class="group first:border-t md:first:border-t-0 border-b md:border-b-0">
      <AnwAccordion
        :id="faq.id"
        :label="faq.label"
        size="large"
        class="-mx-4 md:mx-0 md:border-t md:border-x md:group-last:border-b md:group-first:rounded-t-[5px] md:group-last:rounded-b-[5px]"
        @click="accordionTogglerHelper(faq.id)">
        <div v-html="faq.content" />
        <div v-if="faq.id === 'faqContact'" class="flex flex-col sm:flex-row gap-3 mt-4">
          <a
            :href="`${uri}${DIRECT_MESSAGE}`"
            class="anw-btn anw-btn-primary !min-h-0 grow hover:!no-underline text-center"
            >{{ t('faqLinkDirectMessage') }}</a
          >
          <a
            href="#kontakt"
            class="anw-btn anw-btn-secondary-outline !min-h-0 grow hover:!no-underline text-center"
            @click.prevent="scrollToSection(NAVBAR_ITEMS.CONTACT)"
            >{{ t('faqContactInfo') }}</a
          >
        </div>
      </AnwAccordion>
    </li>
  </ul>
</template>
