<!-- eslint-disable @typescript-eslint/ban-ts-comment -->
<script setup lang="ts">
import { AnwApiMap } from '@anwalt.de/tailwind-design-system';
import type { ContactDetail } from 'shared/types/ContactDetail';
import NoMapAvailable from './NoMapAvailable.vue';
import MapPlaceHolder from './MapPlaceHolder.vue';
import { useCookie } from '@/modules/profile-page/composables/useCookie';
import { AddressHelper } from '@/modules/profile-page/helpers/AddressHelper';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';
defineProps<{
  mapInformation: ContactDetail;
}>();

const { showCookiesPlaceholder } = useCookie();
const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
const { formattedAddress } = AddressHelper();

const { t } = useI18n();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();
updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionOnMapRoute'),
});
</script>

<template>
  <div class="lg:order-first lg:row-span-3">
    <MapPlaceHolder v-if="showCookiesPlaceholder" />
    <div v-else>
      <NoMapAvailable v-if="!mapInformation.coordinates" />
      <AnwApiMap
        v-else
        :apiKey="apiKey"
        :destination-address="formattedAddress(mapInformation.address)"
        :ref-map-container="mapInformation.id"
        :data-attributes="dataAttributesGA"
        :marker="{ lat: mapInformation.coordinates.latitude, lng: mapInformation.coordinates.longitude }"
        mapHeight="190px" />
    </div>
  </div>
</template>
