import { useBreadCrumbsStore } from '@/shared/stores/BreadcrumbsStore';
import { useUserStore } from '../stores/UserStore';

export const initBreadcrumbs = () => {
  const breadcrumbsStore = useBreadCrumbsStore();
  const { displayedName } = useUserStore();

  breadcrumbsStore.$patch((state) => {
    state.breadcrumbsPaths.push({ url: '', text: displayedName });
    state.breadcrumbsLabel = displayedName;
  });
};
