<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { AnwButton } from '@anwalt.de/tailwind-design-system';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { PhoneNumberHelper } from '@/modules/profile-page/helpers/PhoneNumberHelper';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';

const { t } = useI18n();
const { fullPhoneNumber } = PhoneNumberHelper();

const isShown = ref(false);
const { contactDetails, modifiedContactDetails } = storeToRefs(useContactDetailsStore());

const label = computed(() =>
  isShown.value ? fullPhoneNumber(contactDetails.value, modifiedContactDetails.value[0].id) : t('sidebarCall')
);

const { userId } = storeToRefs(useUserStore());
const statistic = inject(statisticKey);
const onClick = () => {
  isShown.value = true;
  statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_PHONE, entityId: userId.value });
};

const { dataAttributesGA, updateAttributeGA } = useGoogleAnalytics();
onMounted(() => {
  updateAttributeGA({
    [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
    [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkphoneNumber'),
  });
});
</script>

<template>
  <AnwButton
    v-bind="dataAttributesGA"
    class="hidden xl:inline-flex"
    :class="{ 'cursor-text select-all': isShown }"
    @click="onClick"
    :label="label"
    :icon="faPhone"
    icon-size="16"
    appearance="primaryOutline" />
</template>
