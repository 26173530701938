<script setup lang="ts">
import { AnwAlert } from '@anwalt.de/tailwind-design-system';
import { useAbsenceModeStore } from '../../stores/AbsenceModeStore';

const { isActive, absenceText } = useAbsenceModeStore();
</script>

<template>
  <AnwAlert
    v-if="isActive"
    class="my-6 2xl:mt-0"
    appearance="info"
    :label="absenceText"
    width="full"
    :icon="true"
    :rounded="true" />
</template>
