<script setup lang="ts">
import FaqMore from '@/modules/profile-page/components/Faq/FaqMore.vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';

const { t } = useI18n();

const { displayedName } = useUserStore();
</script>
<template>
  <div>
    <h2 class="text-lg/[24px] md:text-[32px]/[40px] font-bold mb-4 md:mb-2">{{ t('faqHeading') }}</h2>
    <p class="hidden md:block text-base text-neutral-600 mb-4 2xl:max-w-[486px]">
      {{ t('faqDescription1') }}<br />
      {{ t('faqDescription2', { name: displayedName }) }}
    </p>
    <FaqMore class="hidden xl:block" />
  </div>
</template>
