<script setup lang="ts">
import { AnwHeading } from '@anwalt.de/tailwind-design-system';
import { AnwLists } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';

const { t } = useI18n();
const { languageList } = useUserStore();

const langLists = languageList.map(function (item) {
  return {
    content: item.name,
  };
});
</script>

<template>
  <section v-if="languageList">
    <AnwHeading :heading="t('languages')" :icon="faLanguage" headingClasses="mb-4" />
    <AnwLists :args="langLists" background="info" />
  </section>
</template>
