import { useI18n } from 'vue-i18n';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '../../../../shared/constants/ProfileProductId';
import { useArticleStore } from '@/modules/profile-page/stores/ArticleStore';
import { useProductStore } from '@/modules/profile-page/stores/ProductStore';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { storeToRefs } from 'pinia';
import { GenderHelper } from '@/shared/helper/GenderHelper';

export const NavbarHelper = () => {
  const createNavbarItems = () => {
    const { t } = useI18n();

    const { colleaguesSpecificationNavbar } = GenderHelper();

    const { modifiedContactDetails } = storeToRefs(useContactDetailsStore());
    const { hasKap } = useCompanyDetailsStore();

    const { profileProductId } = useProfileProductId();
    const pronouns = profileProductId === LAWYER ? 'mich' : 'uns';

    const { rating } = useRatingStore();
    const reviewCount = rating.ratingCount;

    const { articleList, articleSummary } = useArticleStore();
    const { products } = useProductStore();

    const contactHeading = () => {
      if (modifiedContactDetails.value.length === 1) {
        return t('contactNavbar.single');
      } else {
        return t('contactNavbar.plural') + ` (${modifiedContactDetails.value.length})`;
      }
    };

    const navbarItems = [
      {
        id: NAVBAR_ITEMS.ABOUT,
        label: t('aboutMeHeading', { pronouns }),
        visible: true,
      },
      {
        id: NAVBAR_ITEMS.REVIEW,
        label: t('reviewsHeadingCount', { count: reviewCount > 0 ? `(${reviewCount})` : '' }),
        visible: rating.isRateable,
      },
      {
        id: NAVBAR_ITEMS.ARTICLE,
        label: t('articlesHeading', { count: `(${articleSummary.totalCount})` }),
        visible: !!articleList.length,
      },
      {
        id: NAVBAR_ITEMS.PRODUCTS,
        label: t('productsHeadingNavbar', { count: products.length }),
        visible: products.length,
      },
      {
        id: NAVBAR_ITEMS.COMPANY,
        label: colleaguesSpecificationNavbar(),
        visible: profileProductId === LAWYER && hasKap,
      },
      {
        id: NAVBAR_ITEMS.CONTACT,
        label: contactHeading(),
        visible: true,
      },
    ];

    return navbarItems.filter((item) => {
      return item.visible;
    });
  };

  return { createNavbarItems };
};
