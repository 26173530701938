<script setup lang="ts">
import ProductsHeader from '@/modules/profile-page/components/Products/ProductsHeader.vue';
import ProductsList from '@/modules/profile-page/components/Products/ProductsList.vue';
import { useProductStore } from '@/modules/profile-page/stores/ProductStore';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';

const { products } = useProductStore();
</script>

<template>
  <section class="anw-profile-card" :id="NAVBAR_ITEMS.PRODUCTS" v-if="products.length">
    <ProductsHeader />
    <ProductsList />
  </section>
</template>
