<script setup lang="ts">
import { AnwBadge, AnwInitialsBadge } from '@anwalt.de/tailwind-design-system';
import { AnwRatingStars } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { DateHelper } from '@/shared/helper/DateHelper';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { storeToRefs } from 'pinia';
import { AnwReadMoreExternal } from '@anwalt.de/tailwind-design-system';
import { RATING_LINK } from '@shared/constants/Links';
import { usePeriodInitials } from '@/shared/composables/periodInitials';

const { t } = useI18n();
const { uri, displayedName } = storeToRefs(useUserStore());
const { lastReview, rating } = useRatingStore();
const { formatDate, formatHour } = DateHelper();
</script>
<template>
  <div
    v-if="lastReview && rating.isRateable"
    class="bg-white p-5 border border-neutral-300 rounded-xl grid grid-cols-[40px_minmax(0,_1fr)] gap-x-3 gap-y-2 mt-6">
    <AnwInitialsBadge :content="lastReview.reviewer" class="col-start-1 col-span-1" />
    <div class="flex flex-wrap items-center gap-x-3">
      <AnwRatingStars :ratingAverage="lastReview.rating" class="!m-0" />
      <span class="w-full md:w-auto text-sm/[22px] text-neutral-700 order-last md:order-none">{{
        t('reviewedFromAt', {
          initials: usePeriodInitials(lastReview.reviewer),
          date: formatDate(lastReview.displayDate),
          hour: formatHour(lastReview.displayDate),
        })
      }}</span>
    </div>
    <div class="col-start-1 col-span-2 sm:col-start-2 sm:col-span-1">
      <div>
        <span class="inline-block mr-3 text-slate-900 text-base font-semibold leading-normal mb-1.5">{{
          lastReview.header
        }}</span>
        <div v-if="lastReview.practiceAreas" class="inline-flex mb-2">
          <AnwBadge v-for="practiceArea in lastReview.practiceAreas" :key="practiceArea">{{ practiceArea }}</AnwBadge>
        </div>
      </div>
      <AnwReadMoreExternal
        :text="lastReview.shortMessage"
        :show-follow-link="false"
        class="text-sm/[22px] text-neutral-500 line-clamp-3" />
      <p class="mt-2">
        <a
          :href="uri + RATING_LINK"
          class="text-sm/[22px] font-semibold"
          :title="t('showAllRatingsTitle', { name: displayedName })">
          {{ t('showAllRatings', { count: rating.ratingCount }) }}
        </a>
      </p>
    </div>
  </div>
</template>
