<script setup lang="ts">
import type { CompanyDetails } from 'shared/types/CompanyDetails';
import { inject } from 'vue';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { useUserStore } from '../../stores/UserStore';
import { storeToRefs } from 'pinia';

const { userId } = storeToRefs(useUserStore());

defineOptions({
  inheritAttrs: false,
});

defineProps<{
  companyDetails: CompanyDetails | undefined;
  imgUrl: string;
  hasKap: boolean;
}>();

const { t } = useI18n();
const { dataAttributesGA } = useGoogleAnalytics();

const statistic = inject(statisticKey);
</script>

<template>
  <figure v-if="hasKap">
    <a
      v-bind="$attrs"
      :href="companyDetails?.uri"
      :title="companyDetails?.name"
      @click="
        statistic?.sendCounter({
          counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_LAW_OFFICE,
          entityId: userId,
        })
      ">
      <img
        :src="imgUrl"
        :alt="companyDetails?.name"
        v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyLogo') }" />
      <figcaption v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyName') }">
        {{ companyDetails?.name }}
      </figcaption>
    </a>
  </figure>
  <figure v-else v-bind="$attrs">
    <img :src="imgUrl" :alt="companyDetails?.name" />
    <figcaption>
      {{ companyDetails?.name }}
    </figcaption>
  </figure>
</template>
