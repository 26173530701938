<script setup lang="ts">
import { onMounted } from 'vue';
import ClickToCall from '../Buttons/ClickToCall.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { useI18n } from 'vue-i18n';
import { DIRECT_MESSAGE } from '../../constants/Links';
import { useUserStore } from '../../stores/UserStore';
import ShowNumberButton from '../Buttons/ShowNumberButton.vue';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useMediaQuery } from '@vueuse/core';
import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';

const { uri } = useUserStore();
const { canReceiveMessages } = useSettingsStore();
const { t } = useI18n();

const isLargeScreen = useMediaQuery('(min-width: 1366px)');

const { dataAttributesGA, updateAttributeGA } = useGoogleAnalytics();

onMounted(() => {
  updateAttributeGA({
    [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
    [GA_ATRIBUTES.ACTION]: isLargeScreen.value ? t('eventActionClickNext') : t('eventActionClickMessageSentMobile'),
  });
});
</script>

<template>
  <div
    class="flex flex-row-reverse gap-2.5 xl:flex-col py-4 xl:p-6 xl:border-t xl:border-neutral-200 xl:first:border-0">
    <a
      v-if="canReceiveMessages"
      :href="uri + DIRECT_MESSAGE"
      class="flex anw-primary-link !text-white anw-btn !no-underline grow"
      :title="t('sidebarContact')"
      v-bind="dataAttributesGA">
      <span class="mr-2 inline-block">
        <FontAwesomeIcon :icon="faEnvelope" class="text-[17px]" aria-hidden="true" />
      </span>
      {{ t('sidebarContact') }}
    </a>
    <ClickToCall />
    <ShowNumberButton />
  </div>
</template>
