<script setup lang="ts">
import { useVideosStore } from '@/modules/profile-page/stores/VideosStore';
import VideoFrame from './VideoFrame.vue';

const { profileVideo } = useVideosStore();
</script>

<template>
  <VideoFrame v-if="profileVideo" :video-src="profileVideo" />
</template>
