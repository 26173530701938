<script setup lang="ts">
import { useCookie } from '@/modules/profile-page/composables/useCookie';
import VideoPlaceholder from './VideoPlaceholder.vue';
import { getVideoEmbedSrc } from '@/modules/profile-page/helpers/GetVideoEmbedSrc';

const { showCookiesPlaceholder } = useCookie();

defineProps<{
  videoSrc: string;
}>();
</script>

<template>
  <div v-if="videoSrc.length">
    <slot name="heading" />
    <VideoPlaceholder v-if="showCookiesPlaceholder" @clicked-accept-cookies="$emit('clickedAcceptCookies', $event)" />
    <iframe
      v-else
      class="embed-responsive-item w-full rounded-lg aspect-video"
      title="video player"
      :src="getVideoEmbedSrc(videoSrc)"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
  </div>
</template>
