import { ArticleSummary } from '@shared/types/ArticleSummary';
import { useArticleStore } from '@/modules/profile-page/stores/ArticleStore';

export const initArticleSummary = (articleSummary: ArticleSummary) => {
  const articleStore = useArticleStore();

  articleStore.$patch((state) => {
    state.articleSummary = { ...articleSummary };
  });
};
