import { SOCIAL_MEDIAS_SHARE_LINKS } from '@/modules/profile-page/constants/Links';

export const ShareContactHelper = () => {
  const socialMediaShareUrl = (type: string, profile: string | null, text: string | null): string => {
    if (text && text.length > 0) {
      return SOCIAL_MEDIAS_SHARE_LINKS[type.toUpperCase()] + text + profile;
    }
    return SOCIAL_MEDIAS_SHARE_LINKS[type.toUpperCase()] + profile;
  };

  return { socialMediaShareUrl };
};
