export const LEGAL_PRODUCTS = [
  'fuenf_minuten_mit_einem_anwalt',
  'raus_aus_der_abofalle',
  'ausfuehrliche_erstberatung',
  'vorsorgevollmacht_und_patientenverfuegung',
  'kurzberatung_bis_zu_30_minuten',
  'akteneinsicht_anfordern',
  'anwaltsinkasso',
  'vertragspruefung_fuer_privatpersonen',
];
