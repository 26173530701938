import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Videos } from '@shared/types/Videos';

export const useVideosStore = defineStore('videos', () => {
  const aboutMeVideo = ref<Videos['about']>(null);
  const profileVideo = ref<Videos['profile']>();

  return {
    aboutMeVideo,
    profileVideo,
  };
});
