/* eslint-disable @typescript-eslint/ban-ts-comment */
import { onMounted, ref, computed } from 'vue';
import { COOKIE_EVENTS } from '@/modules/profile-page/constants/Cookie';

declare const window: any;

export const useCookie = () => {
  const isCookieAccepted = ref(false);

  onMounted(() => {
    // @ts-ignore
    isCookieAccepted.value = window.Cookiebot?.consent.marketing ?? false;
    window.addEventListener(COOKIE_EVENTS.COOKIEBOT_ON_ACCEPT, handleAccept);
    window.addEventListener(COOKIE_EVENTS.COOKIEBOT_ON_DECLINE, handleDecline);
  });

  // @ts-ignore
  const handleAccept = () => window.Cookiebot?.consent.marketing && (isCookieAccepted.value = true);

  const handleDecline = () => (isCookieAccepted.value = false);

  const showCookiesPlaceholder = computed(() => {
    return !isCookieAccepted.value && import.meta.env.VITE_COOKIE_BANNER === 'true';
  });

  const acceptCookies = function () {
    if (typeof window.Cookiebot === 'undefined' || typeof window.Cookiebot.renew !== 'function') {
      console.warn('Cookiebot is not loaded');
      return;
    }
    window.Cookiebot.renew();
  };

  return {
    showCookiesPlaceholder,
    acceptCookies,
  };
};
