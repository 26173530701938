<script setup lang="ts">
import SocialMedia from '@/modules/profile-page/components/About/SocialMedia.vue';
import { useI18n } from 'vue-i18n';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { computed } from 'vue';
import { LAWYER } from '@shared/constants/ProfileProductId';

const { t } = useI18n();

const { profileProductId } = useProfileProductId();

const pronouns = computed(() => (profileProductId === LAWYER ? 'mich' : 'uns'));
</script>

<template>
  <div class="flex flex-wrap justify-between pb-3">
    <h2 class="flex items-center gap-2 font-semibold text-lg mb-0">{{ t('aboutMeHeading', { pronouns }) }}</h2>
    <SocialMedia />
  </div>
</template>
