import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { ContactDetailType } from '../../../../shared/types/ContactDetail';
import { ContactDetailHelper } from '../../../../shared/helper/ContactDetailHelper';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { useUserStore } from '../stores/UserStore';

export const useSeoLink = () => {
  const { t } = useI18n();

  const { getContactDetail } = ContactDetailHelper();
  const { contactDetails } = storeToRefs(useContactDetailsStore());
  const businessContact = getContactDetail(contactDetails.value, ContactDetailType.BUSINESS);

  const { displayedName, about } = useUserStore();

  const generateAnchorTag = (href: string | null, location: string) =>
    `<a class="text-sm" href="${href}" title="${t('yourLawyerTitle', {
      location,
    })}">${t('breadcrumbsPath')} ${location}</a>`;

  const generateHtml = (cityLink: string, countryLink: string) => `<div class="my-5 text-sm">
    <span>${t('listed', { name: displayedName })} </span>
    ${cityLink}
    und
    ${countryLink}.
  </div>`;

  const seoLink = computed(() => {
    const cityLink = generateAnchorTag(businessContact?.seoCityUri, businessContact.address?.city);
    const countryLink = generateAnchorTag(businessContact?.seoCountryUri, businessContact.address?.country);

    return generateHtml(cityLink, countryLink);
  });

  const aboutMeText = ref(about + seoLink.value);

  return {
    aboutMeText,
  };
};
