import { ContactDetail, ContactDetailType, SEARCH_TYPE_KEY } from '../types/ContactDetail';

export const ContactDetailHelper = () => {
  const getKeyToSerach = (value: ContactDetailType | number): keyof ContactDetail =>
    typeof value === 'number' ? SEARCH_TYPE_KEY.ID : SEARCH_TYPE_KEY.TYPE;

  const getContactDetail = (contactDetails: ContactDetail[], value: ContactDetailType | number): ContactDetail => {
    const keyType = getKeyToSerach(value);
    return contactDetails.filter((contactDetail: ContactDetail) => contactDetail[keyType] === value)[0];
  };

  return { getContactDetail };
};
