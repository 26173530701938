<script setup lang="ts">
import { faVideo } from '@fortawesome/pro-light-svg-icons';
import { AnwHeading } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { useVideosStore } from '@/modules/profile-page/stores/VideosStore';
import YoutubeFrame from './VideoFrame.vue';

const { t } = useI18n();

const { aboutMeVideo } = useVideosStore();
</script>

<template>
  <YoutubeFrame v-if="aboutMeVideo" :video-src="aboutMeVideo">
    <template #heading>
      <AnwHeading :heading="t('aboutMeVideo')" tag="h3" :icon="faVideo" headingClasses="mb-4"
    /></template>
  </YoutubeFrame>
</template>
