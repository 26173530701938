<script setup lang="ts">
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { AnwCopyToClipboard } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { storeToRefs } from 'pinia';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps<{ dataContactValue: string }>();

const { userId } = storeToRefs(useUserStore());
const statistic = inject(statisticKey);

const { t } = useI18n();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkEmail'),
  [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
});
</script>

<template>
  <p class="flex gap-3 text-left">
    <span class="flex justify-center w-5 shrink-0">
      <FontAwesomeIcon :icon="faEnvelope" class="fa-1x mt-[3px]" aria-hidden="true" />
    </span>
    <span class="break-all">
      <span class="mr-1">{{ dataContactValue }}</span>

      <AnwCopyToClipboard
        appearance="icon"
        :content="dataContactValue"
        :icon-size="15"
        v-bind="dataAttributesGA"
        @click="statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_EMAIL, entityId: userId })" />
    </span>
  </p>
</template>
