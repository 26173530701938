import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';
import Lawyer from 'shared/types/Lawyer';

export const initLawyerCompanyDetails = (lawyerData: Lawyer) => {
  const companyDetailsStore = useCompanyDetailsStore();

  companyDetailsStore.$patch((state) => {
    state.companyDetails = { ...lawyerData.company };
  });
};
