<script setup lang="ts">
import { computed } from 'vue';
import CompanyDetail from '@/modules/profile-page/components/Company/CompanyDetail.vue';
import NewLawyerBadge from './Badges/NewLawyerBadge.vue';
import VerifiedBadge from './Badges/VerifiedBadge.vue';
import ShareButton from './Buttons/ShareButton.vue';
import Quote from '../components/Informations/Quote.vue';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import LocationsInformation from './Address/LocationsInformation.vue';
import { AnwAvatar, AnwAvatarVideo, AnwRatingMinimal } from '@anwalt.de/tailwind-design-system';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { storeToRefs } from 'pinia';
import { useImage } from '@shared/composables/image';
import { RATING_LINK } from '@shared/constants/Links';
import { useVideosStore } from '../stores/VideosStore';
import ProfileVideo from './Video/ProfileVideo.vue';
import { useI18n } from 'vue-i18n';

const { profileProductId } = storeToRefs(useProfileProductId());
const { profile } = useLawyerStore();

const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();

const { imagePath, displayedName, uri } = storeToRefs(useUserStore());

const profileImageAlt = computed(() => {
  return `${getTranslationByProductId('profileImage', profileProductId.value, {
    n: getGenderValueForTranslation(profile.sex),
  })} ${displayedName.value.trim()}`;
});

const { t } = useI18n();

const { rating } = storeToRefs(useRatingStore());
const imageUrlHost = import.meta.env.VITE_IMAGES_URL;

const { computedProfileImagePath } = useImage(imageUrlHost, imagePath.value, profileProductId.value);

const { profileVideo } = useVideosStore();

const avatarConfig = computed(() => {
  if (profileProductId.value === COMPANY || !profileVideo)
    return {
      size: 'xl',
      aspect: profileProductId.value === COMPANY ? 'auto' : 'square',
      component: AnwAvatar,
      class:
        'md:w-[180px] object-cover object-center shadow-lg outline outline-[rgba(0,0,0,0.08)] outline-1 outline-offset-[-1px]',
    };
  return {
    component: AnwAvatarVideo,
    modalHeading: t('profileVideo', { name: displayedName.value }, getGenderValueForTranslation(profile.sex)),
  };
});
</script>

<template>
  <div class="grid gap-y-2 gap-x-5">
    <NewLawyerBadge />
    <div
      class="grid items-start grid-cols-[120px_minmax(0,_1fr)] md:grid-cols-[180px_minmax(0,_1fr)] gap-x-5 gap-y-4 relative mb-3.5">
      <figure class="md:row-start-1" :class="profileProductId === 'lawyer' ? 'md:row-end-4' : 'md:row-end-3'">
        <component
          :is="avatarConfig.component"
          :src="computedProfileImagePath(180)"
          :alt="profileImageAlt"
          :size="avatarConfig?.size"
          :class="avatarConfig?.class"
          :aspect="avatarConfig?.aspect"
          :modal-heading="avatarConfig?.modalHeading">
          <template #video="{ toggleLightbox, isOpen }">
            <ProfileVideo @clicked-accept-cookies="toggleLightbox" v-if="isOpen" />
          </template>
        </component>
      </figure>
      <div class="md:row-span-1 flex gap-4 items-start">
        <div class="grow">
          <VerifiedBadge />
          <h1 class="text-lg lg:text-[30px]/[38px] font-bold mb-0">{{ displayedName }}</h1>
          <div v-if="rating.ratingCount !== 0 && rating.isRateable" class="xl:hidden mt-3">
            <AnwRatingMinimal
              :ratingAverage="rating.ratingAverage"
              :ratingCount="rating.ratingCount"
              :ratingLink="uri + RATING_LINK" />
          </div>
        </div>
        <ShareButton />
      </div>
      <CompanyDetail class="col-span-2 md:col-start-2 md:col-end-2" />
      <LocationsInformation class="col-span-2 md:col-start-2 md:col-end-2" />
    </div>
    <Quote />
  </div>
</template>
