<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { AnwRatingBadge } from '@anwalt.de/tailwind-design-system';
import { RatingHelper } from '@/shared/helper/RatingHelper';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { ScrollHelper } from '../../helpers/ScrollHelper';
import { useUserStore } from '../../stores/UserStore';
import { RATING_LINK } from '@shared/constants/Links';
import { useRating } from '@/shared/composables/rating';
import { storeToRefs } from 'pinia';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useI18n } from 'vue-i18n';

const { rating } = storeToRefs(useRatingStore());
const { showRatingBadge } = useRating(rating);

const isVisible = ref(false);
const { displayedName, uri } = useUserStore();

onMounted(() => {
  ScrollHelper().watchElement(document.getElementsByTagName('h1')[0], (value) => {
    isVisible.value = value;
  });
});

const { t } = useI18n();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.LABEL]: `${t('eventLabelProfile')} ${t('eventLabelHeader')}`,
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkRating'),
});
</script>
<template>
  <div class="hidden xl:block">
    <p v-if="!isVisible" class="px-6 py-4 text-2xl/[30px] font-bold">
      {{ displayedName }}
    </p>
    <div class="px-6 py-4 border-t border-neutral-200 first:border-0">
      <a
        v-if="rating.ratingCount !== 0 && rating.isRateable"
        :href="uri + RATING_LINK"
        v-bind="dataAttributesGA"
        class="lg:flex items-center justify-between !no-underline cursor-pointer !text-neutral-700 rounded-md focus:outline-none focus:ring">
        <AnwRatingBadge class="p-0" :ratingAverage="rating.ratingAverage" :showRatingText="showRatingBadge" />
        <p class="!no-underline cursor-pointer !text-neutral-700">
          ({{ RatingHelper().getNumberOfRatingsLabel(rating.ratingCount) }})
        </p>
      </a>
    </div>
  </div>
</template>
