import { useQualificationListStore } from '@/modules/profile-page/stores/QualificationListStore';
import { QualificationListProfile } from '@shared/types/QualificationListProfile';

export const initQualificationList = (qualificationList: QualificationListProfile) => {
  const qualificationListStore = useQualificationListStore();

  qualificationListStore.$patch((state) => {
    state.qualificationList = [...qualificationList];
  });
};
