import { useColleagueListStore } from '@/modules/profile-page/stores/ColleagueListStore';
import { CompanyColleague } from '@shared/types/CompanyDetails';

export const initColleagueList = (contactList: CompanyColleague[]) => {
  const colleagueList = useColleagueListStore();

  colleagueList.$patch((state) => {
    state.colleagueList = [...contactList];
  });
};
