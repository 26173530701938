import { LEGAL_PRODUCTS } from '../constants/LegalProducts';

export const useLegalProductsImage = () => {
  const sanitizedName = (legalProductsName: string) => {
    const map: Record<string, string> = {
      ü: 'ue',
      ö: 'oe',
      ä: 'ae',
      5: 'fuenf',
      ' ': '_',
    };
    const sanitizedName = legalProductsName.replace(/[üöä5 ]/g, (match) => map[match]).toLowerCase();
    return LEGAL_PRODUCTS.some((legalProduct) => sanitizedName === legalProduct)
      ? sanitizedName
      : `akteneinsicht_anfordern`;
  };

  const getImageUrl = (legalProductsName: string) => {
    const sanitizeLegalProductName = sanitizedName(legalProductsName);

    return `${import.meta.env.VITE_STATIC_IMAGES_URL}/legal-products/${sanitizeLegalProductName}.png `;
  };

  return { getImageUrl };
};
