import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { CompanyDetails } from 'shared/types/CompanyDetails';
import { useColleagueListStore } from './ColleagueListStore';

export const useCompanyDetailsStore = defineStore('companyDetails', () => {
  const companyDetails = ref<CompanyDetails | undefined>();

  const hasKap = computed(() => !!(companyDetails.value?.uri && useColleagueListStore().colleagueList.length));

  return { companyDetails, hasKap };
});
