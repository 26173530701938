import { useColleagueListStore } from '@/modules/profile-page/stores/ColleagueListStore';
import { SEX } from '@shared/types/Sex';
import { useI18n } from 'vue-i18n';

export const GenderHelper = () => {
  const colleaguesSpecificationNavbar = () => {
    const { t } = useI18n();

    const femaleCount = getGenderCount(SEX.FEMALE);
    const maleCount = getGenderCount(SEX.MALE);

    const counter = () => {
      const counter = femaleCount + maleCount;
      if (counter > 1) return ` (${counter})`;
      return '';
    };

    if (femaleCount && !maleCount) {
      return t('companyLawyersWomanNavbar', femaleCount) + counter();
    }
    if (femaleCount && maleCount) {
      return t('companyLawyersNavbar', maleCount + femaleCount) + counter();
    }
    return t('companyLawyersNavbar', maleCount) + counter();
  };

  const colleaguesSpecification = () => {
    const { t } = useI18n();

    const femaleCount = getGenderCount(SEX.FEMALE);
    const maleCount = getGenderCount(SEX.MALE);

    if (femaleCount && !maleCount) {
      return t('companyLawyersWoman', femaleCount);
    }
    if (femaleCount && maleCount) {
      return t('companyLawyers', maleCount + femaleCount);
    }
    return t('companyLawyers', maleCount);
  };

  const companyLawyersGender = () => {
    const femaleCount = getGenderCount(SEX.FEMALE);
    const maleCount = getGenderCount(SEX.MALE);

    if (femaleCount && !maleCount) {
      return 2;
    } else {
      return 1;
    }
  };

  const getGenderCount = (sex: SEX) => useColleagueListStore().colleagueList.filter((col) => col.sex === sex).length;

  return { colleaguesSpecificationNavbar, colleaguesSpecification, companyLawyersGender };
};
