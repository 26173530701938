import { useProductStore } from '@/modules/profile-page/stores/ProductStore';
import { Product } from '@shared/types/Product';

export const initProducts = (productList: Product[]) => {
  const productsStore = useProductStore();

  productsStore.$patch((state) => {
    state.products = [...productList];
  });
};
