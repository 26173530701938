<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { AnwTooltip } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { CONTACT } from '@/modules/profile-page/constants/Links';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { type ModifiedContactDetail } from '@shared/types/ContactDetail';
import { Geolocation } from '@/modules/profile-page/helpers/Geolocation';

const { t } = useI18n();
const isToolTipVisible = ref(false);
const ignoredButtonRef = ref();
const { uri, displayedName } = useUserStore();

const tooltipContent = ref(t('locationInformation'));

const { modifiedContactDetails } = storeToRefs(useContactDetailsStore());
const distanceInKm = computed(() => (modifiedContactDetails.value as ModifiedContactDetail[])[0]?.calculatedDistance);

onMounted(() => {
  Geolocation.getZipcodeAndCity().then((result) => {
    if (!result) return;
    tooltipContent.value = t('locationInformation', { location: result }, 2);
  });
});
</script>

<template>
  <div class="flex gap-1" v-if="distanceInKm">
    <a :href="uri + CONTACT" class="text-xs !text-neutral-800" :title="t('distanceTitle', { name: displayedName })">
      {{
        t('distance', {
          km: Intl.NumberFormat('de-DE').format(distanceInKm),
        })
      }}</a
    >
    <div class="flex items-center relative" :class="{ 'z-[1040]': isToolTipVisible }">
      <button
        ref="ignoredButtonRef"
        type="button"
        class="grid hover:text-info-500"
        @click="isToolTipVisible = !isToolTipVisible">
        <FontAwesomeIcon :icon="faCircleInfo" class="text-[12px]" fixed-width />
        <span class="sr-only">{{ t('furtherInformation') }}</span>
      </button>
      <span class="relative lg:static sm:-left-36 md:-left-16">
        <AnwTooltip
          v-model:is-visible="isToolTipVisible"
          :title="'Information'"
          :icon="faCircleInfo"
          position="bottom-right"
          :ignore-el-ref="ignoredButtonRef">
          <div class="text-neutral-900 anw-text-p1">
            {{ tooltipContent }}
          </div>
        </AnwTooltip>
      </span>
    </div>
  </div>
  <div v-else>
    <a :href="uri + CONTACT" class="text-xs !text-neutral-800"> {{ t('distanceTitle', { name: displayedName }) }}</a>
  </div>
</template>
