import { UserResponse } from '@shared/types/User';
import { initUser } from './InitUser';
import { initApp } from '@/shared/inits/InitApp';
import { initProfileType } from './InitProfileType';
import { initContactDetail } from './InitContactDetail';
import { initPracticeAreas } from '@/shared/inits/InitPracticeAreas';
import { initSocialMedias } from './InitSocialMedias';
import { initArticles } from './InitArticles';
import { initProducts } from './InitProducts';
import { initRatingSummary } from '@/modules/profile-page/inits/InitRatingSummary';
import { ProfileProductHelper } from '@shared/helper/ProfileProductHelper';
import { initBreadcrumbs } from './InitBreadcrumbs';
import { IAppConfig } from '@shared/types/AppData';
import { initQualificationList } from '@/modules/profile-page/inits/initQualificationList';
import { initAssociationsList } from '@/modules/profile-page/inits/initAssociationsList';
import { initGalleryStore } from '@/modules/profile-page/inits/initGalleryStore';
import { Geolocation } from '@/modules/profile-page/helpers/Geolocation';
import { initColleagueList } from './InitColleagueList';
import { initArticleSummary } from '@/modules/profile-page/inits/InitArticleSummary';
import { initVideos } from './InitVideos';

const initData = (userData: UserResponse, appData: IAppConfig) => {
  const { getProfileProductData, getProfileProductId } = ProfileProductHelper(userData);
  const profileProductId = getProfileProductId();
  const profileData = getProfileProductData(profileProductId);

  Geolocation.init(appData.geoCoordinates[0], appData.geoCoordinates[1]);

  initArticles(userData.articlePreviews);
  initArticleSummary(userData.articleSummary);
  initApp(appData);
  initRatingSummary(userData.ratingSummary, userData.reviewList);

  if (profileData) {
    initColleagueList(profileData.colleagueList);
    initAssociationsList(profileData.associationList);
    initProfileType(profileProductId, profileData);
    initUser(profileData);
    initContactDetail(profileData.contactDetailList);
    initPracticeAreas(profileData.practiceAreaList);
    initSocialMedias(profileData.socialMedia);
    initProducts(profileData.legalProductList);
    initQualificationList(profileData.qualificationList);
    initGalleryStore(profileData.gallery);
    initVideos(profileData.videos);
    initBreadcrumbs();
  }
};

export { initData };
