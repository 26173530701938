<script setup lang="ts">
import { AnwAccordion } from '@anwalt.de/tailwind-design-system';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { faClock, faVideo, faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';
import { useLawyerStore } from '@/shared/stores/LawyerStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '@shared/constants/ProfileProductId';

const { offersOnlineAdvice, onlineAdviceText, admissionDate, averageResponseTime } = useSettingsStore();
const { profileProductId } = useProfileProductId();
const { profile } = useLawyerStore();
const { t } = useI18n();

const admission = new Date(admissionDate);

const showAdmission = () => {
  return countExperience() > 3 && admissionDate !== null;
};

const setYear = () => {
  return admission.getFullYear();
};

const countExperience = () => {
  const today = new Date();
  return today.getFullYear() - admission.getFullYear();
};

const averageResponseTimeInHours = () => {
  const replyTimeInHours = averageResponseTime / 3600;

  switch (true) {
    case replyTimeInHours > 0 && replyTimeInHours <= 24:
      return 24;
    case replyTimeInHours > 24 && replyTimeInHours <= 48:
      return 48;
    case replyTimeInHours > 48 && replyTimeInHours <= 72:
      return 72;
    default:
      return 0;
  }
};

const setAdmissionContent = () => {
  return t(`sidebarExperience.${profile.sex}`, { year: setYear(), experience: countExperience() });
};

const sidebarAnswer = () => {
  const translationType = profileProductId === LAWYER ? profile.sex : 'company';
  return t(`sidebarAnswer.${translationType}`, { time: averageResponseTimeInHours() });
};
</script>
<template>
  <div v-if="offersOnlineAdvice || (profileProductId === LAWYER && showAdmission())">
    <AnwAccordion
      v-if="offersOnlineAdvice"
      class="border-t border-neutral-200 first:border-t-0"
      :label="t('sidebarOnlineHeading')">
      <template #icon>
        <fontAwesomeLayers class="text-[34px]">
          <FontAwesomeIcon :icon="faCircle" class="text-[34px] text-success-100" aria-hidden="true" />
          <FontAwesomeIcon :icon="faVideo" class="text-[14px] text-success-600" aria-hidden="true" />
        </fontAwesomeLayers>
      </template>
      <span v-html="onlineAdviceText"></span>
    </AnwAccordion>
    <AnwAccordion
      v-if="false"
      class="border-t border-neutral-200 first:border-t-0"
      :label="t('sidebarAnswerHeading', { time: averageResponseTimeInHours() })">
      <template #icon>
        <fontAwesomeLayers class="text-[34px]">
          <FontAwesomeIcon :icon="faCircle" class="text-[34px] text-neutral-100" aria-hidden="true" />
          <FontAwesomeIcon :icon="faClock" class="text-[14px] text-neutral-500" aria-hidden="true" />
        </fontAwesomeLayers>
        <span class="bg-neutral-100 p-2 rounded-full text-neutral-500">
          <FontAwesomeIcon :icon="faClock" class="text-[14px]" aria-hidden="true" />
        </span>
      </template>
      {{ sidebarAnswer() }}
    </AnwAccordion>
    <AnwAccordion
      v-if="profileProductId === LAWYER && showAdmission()"
      class="border-t border-neutral-200 first:border-t-0"
      :label="t('sidebarExperienceHeading', { experience: countExperience() })">
      <template #icon>
        <fontAwesomeLayers class="text-[34px]">
          <FontAwesomeIcon :icon="faCircle" class="text-[34px] text-neutral-100" aria-hidden="true" />
          <FontAwesomeIcon :icon="faCircleCheck" class="text-[14px] text-neutral-500" aria-hidden="true" />
        </fontAwesomeLayers>
      </template>
      {{ setAdmissionContent() }}
    </AnwAccordion>
  </div>
</template>
<style>
.fake-class-to-render-rating-css {
  color: red;
}
</style>
