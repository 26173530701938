<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { AddressHelper } from '@/modules/profile-page/helpers/AddressHelper';
import { ScrollHelper } from '../../helpers/ScrollHelper';

const { t } = useI18n();

const { scrollToSection } = ScrollHelper();

const { getAdditionalAddress } = AddressHelper();
const additionalAdressList = computed(() => getAdditionalAddress());
</script>

<template>
  <address class="flex gap-4 md:gap-2 not-italic" v-if="additionalAdressList.length">
    <FontAwesomeIcon
      :icon="faLocationDot"
      class="text-[14px] text-neutral-800 pt-0.5 shrink-0 md:opacity-0"
      aria-hidden="true"
      fixed-width />
    <div class="md:flex md:flex-wrap gap-x-1">
      <p class="shrink-0 text-xs">{{ t('additionalAddress', additionalAdressList.length) }}</p>
      <ul class="md:flex md:flex-wrap">
        <li
          class="text-xs mr-1 after:content-[','] last:after:hidden"
          v-for="additionalAdress in additionalAdressList"
          :key="additionalAdress.id">
          <a
            :href="'#' + additionalAdress.id"
            @click.prevent="scrollToSection(additionalAdress.id)"
            class="font-semibold"
            >{{ additionalAdress.address }}<span class="sr-only"> {{ t('additionalAddressItemSrInfo') }}</span></a
          >
        </li>
      </ul>
    </div>
  </address>
</template>
