<script setup lang="ts">
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import { useProductStore } from '@/modules/profile-page/stores/ProductStore';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const { totalProducts } = useProductStore();
</script>
<template>
  <SectionHeadline :headline="t('productsHeading')" tag="h2" :counter="totalProducts" />
</template>
