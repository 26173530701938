<script setup lang="ts">
import CompanyHeader from '@/modules/profile-page/components/Company/CompanyHeader.vue';
import CompanyAbout from '@/modules/profile-page/components/Company/CompanyAbout.vue';
import CompanyLawyers from '@/modules/profile-page/components/Company/CompanyLawyers.vue';
import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { LAWYER } from '@shared/constants/ProfileProductId';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';

const { profileProductId } = useProfileProductId();
const { hasKap } = useCompanyDetailsStore();
</script>

<template>
  <section class="anw-profile-card" v-if="profileProductId === LAWYER && hasKap" :id="NAVBAR_ITEMS.COMPANY">
    <CompanyHeader />
    <CompanyAbout />
    <CompanyLawyers :show-company-lawyers="true" class="py-4" />
  </section>
</template>
