<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { computed } from 'vue';
import { AddressHelper } from '../../helpers/AddressHelper';
import { ScrollHelper } from '../../helpers/ScrollHelper';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import DistanceInKm from './DistanceInKm.vue';
import { useI18n } from 'vue-i18n';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/shared/stores/AppStore';

const { getMainAddress } = AddressHelper();
const { scrollToSection } = ScrollHelper();
const { t } = useI18n();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCityVCard'),
});

const reformatUrl = (url: string) => url.replace(/^https?:\/\/(.*?)\/?$/, `$1`);

const { contactDetails } = storeToRefs(useContactDetailsStore());
const { contactDetailId } = storeToRefs(useAppStore());
const websiteContact = computed(() => {
  const defaultContactDetailWebsite = contactDetails.value.at(0)?.website;
  if (contactDetailId) {
    const contactDetail = contactDetails.value.find((c) => c.id === contactDetailId.value);
    if (contactDetail) {
      return contactDetail.website || defaultContactDetailWebsite;
    }
    return defaultContactDetailWebsite;
  }
  return defaultContactDetailWebsite;
});
</script>

<template>
  <address class="flex gap-4 md:gap-2 not-italic">
    <FontAwesomeIcon
      :icon="faLocationDot"
      class="text-[14px] text-neutral-800 pt-1.5 shrink-0"
      aria-hidden="true"
      fixed-width />
    <div class="grid gap-2">
      <div>
        <div>
          <button
            type="button"
            v-bind="dataAttributesGA"
            @click="scrollToSection(NAVBAR_ITEMS.CONTACT)"
            class="text-sm/[22px] text-info-500 font-semibold hover:underline">
            {{ getMainAddress() }}
          </button>
        </div>
        <DistanceInKm />
      </div>
      <a
        v-if="websiteContact"
        :href="websiteContact"
        class="flex items-center gap-1"
        target="_blank"
        rel="noopener nofollow"
        data-ga-trackingtype="event"
        data-ga-event-category="Aktionen auf Profilseiten"
        data-ga-event-action="Klick auf Link der Homepage Visitenkarte"
        data-ga-event-label="#userId#">
        <span class="text-xs pt-[1px]">{{ reformatUrl(websiteContact) }}</span>
        <FontAwesomeIcon :icon="faArrowUpRightFromSquare" class="text-[12px]" aria-hidden="true" />
      </a>
    </div>
  </address>
</template>
