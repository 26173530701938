<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { PhoneNumberHelper } from '@/modules/profile-page/helpers/PhoneNumberHelper';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { inject } from 'vue';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';

const { t } = useI18n();
const { fullPhoneNumber } = PhoneNumberHelper();
const { contactDetails, modifiedContactDetails } = storeToRefs(useContactDetailsStore());
const { userId } = storeToRefs(useUserStore());
const statistic = inject(statisticKey);
const onClick = () => {
  statistic?.sendCounter({ counterType: COUNTER_TYPES.COUNTER_TYPE_PROFILE_PHONE, entityId: userId.value });
};
</script>

<template>
  <a
    :href="'tel:' + fullPhoneNumber(contactDetails, modifiedContactDetails[0].id)"
    class="anw-btn anw-btn-primary-outline hover:!no-underline xl:hidden"
    @click="onClick">
    <FontAwesomeIcon :icon="faPhone" class="text-[20px]" aria-hidden="true" />
    <span class="sr-only">{{ t('sidebarCall') }}</span>
  </a>
</template>
