export const ScrollHelper = () => {
  const getBoundingClientRect = (target: string | HTMLElement) => {
    const element = typeof target === 'string' ? document.getElementById(target) : target;
    if (!element) return null;
    return element.getBoundingClientRect();
  };
  const scrollToSection = (sectionId: string, offsetTop = 48) => {
    const rect = getBoundingClientRect(sectionId);
    if (!rect) return false;
    const offsetPosition = rect.top + window.scrollY - offsetTop;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const watchElement = (el: HTMLElement, callback: (value: boolean) => void) => {
    const observer = new IntersectionObserver(([entry]) => callback(entry.isIntersecting));
    observer.observe(el);
  };

  return { scrollToSection, getBoundingClientRect, watchElement };
};
