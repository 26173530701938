import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { Product } from '../.../../../../../shared/types/Product';

export const useProductStore = defineStore('products', () => {
  const products = ref<Product[] | never[]>([]);

  const totalProducts = computed(() => products.value.length);

  return { products, totalProducts };
});
