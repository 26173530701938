<script setup lang="ts">
import { AnwRoundButton } from '@anwalt.de/tailwind-design-system';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { ScrollHelper } from '../../helpers/ScrollHelper';
import { onMounted, ref } from 'vue';
import { PROFILE_ID, CONTENT_ID } from '@/modules/profile-page/constants/Target';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { watchElement, scrollToSection } = ScrollHelper();
const isVisible = ref(false);

onMounted(() => {
  const el = document.getElementById(PROFILE_ID) as HTMLElement;
  watchElement(el, (value) => {
    isVisible.value = value;
  });
});
</script>

<template>
  <div
    class="fixed right-4 lg:right-4 bottom-28 md:bottom-4 z-10 transition-all"
    :class="isVisible ? 'invisible opacity-0' : 'visible opacity-100'">
    <AnwRoundButton
      @click="scrollToSection(CONTENT_ID, 0)"
      :srLabel="t('backToTop')"
      :icon="faAngleUp"
      icon-size="24"
      appearance="rounded-full"
      backgroundColor="bg-info-200" />
  </div>
</template>
