import { useArticleStore } from '@/modules/profile-page/stores/ArticleStore';
import { Articles } from 'shared/types/Articles';

export const initArticles = (articleList: Articles) => {
  const articlesStore = useArticleStore();

  articlesStore.$patch((state) => {
    state.articleList = [...articleList];
  });
};
