<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faWheelchair } from '@fortawesome/pro-light-svg-icons';

defineProps<{
  isBarrierFree: boolean;
}>();

const { t } = useI18n();
</script>

<template>
  <div
    v-if="isBarrierFree"
    class="inline-flex items-center gap-1 bg-white px-3 py-1 border border-gray-200 mt-2 rounded-full">
    <FontAwesomeIcon :icon="faWheelchair" class="fa-1x" fixed-width aria-hidden="true" />
    {{ t('contactBarrierFree') }}
  </div>
</template>
