<script setup lang="ts">
import { ref } from 'vue';
import { AnwRoundButton, AnwPopup, AnwLinkSocialMedia, AnwCopyToClipboard } from '@anwalt.de/tailwind-design-system';
import { faShareNodes } from '@fortawesome/pro-solid-svg-icons';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { ShareContactHelper } from '@/modules/profile-page/helpers/ShareContactHelper';
import { useI18n } from 'vue-i18n';
import { SOCIAL_MEDIAS_SHARE_LINKS } from '@/modules/profile-page/constants/Links';
import { SocialMediaKey } from '@shared/types/SocialMedia';

const { t } = useI18n();

const popup = ref(false);
const ignoredButtonRef = ref();

const { uri, displayedName } = useUserStore();

const { socialMediaShareUrl } = ShareContactHelper();

const shareText: { [key: string]: string } = {
  email: t('profileShareMail', { name: displayedName.trim() }),
  whatsapp: t('profileShareWhatsapp', { name: displayedName.trim() }),
};
</script>

<template>
  <div class="hidden xl:block shrink-0 relative z-30">
    <AnwRoundButton
      @click="popup = !popup"
      ref="ignoredButtonRef"
      :srLabel="t('profileShare')"
      :icon="faShareNodes"
      icon-size="22" />
    <AnwPopup :title="t('share')" v-model:is-visible="popup" position="bottom-right" :ignore-el-ref="ignoredButtonRef">
      <ul class="flex gap-4">
        <li v-for="(url, key, index) in SOCIAL_MEDIAS_SHARE_LINKS" :key="index">
          <AnwLinkSocialMedia
            :type="key.toLowerCase() as SocialMediaKey"
            :href="socialMediaShareUrl(key, uri, shareText[key.toLowerCase()] ?? null)" />
        </li>
      </ul>
      <AnwCopyToClipboard appearance="button" :title="t('copyLink')" :content="uri" />
    </AnwPopup>
  </div>
</template>
