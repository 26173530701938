<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/modules/profile-page/stores/SettingsStore';

const { showIsNewBadge } = useSettingsStore();

const { t } = useI18n();
</script>

<template>
  <p v-if="showIsNewBadge" class="text-xs text-primary font-semibold">{{ t('newLawyer') }}</p>
</template>
